var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-list',{ref:"dataList",staticClass:"content md",attrs:{"url":_vm.url,"query":"&sort=-id&expand=district,worshipStatus","entity-name":"龛位","hide-show-detail":"","hide-delete":"","rules":_vm.rules,"columns":_vm.columns,"search-key-type":{ num: 1 },"action":_vm.action,"requestConfig":{ noTempleFilter: true },"before-edit":_vm.beforeEdit,"before-update":_vm.beforeUpdate},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.goOverview}},[_vm._v("龛位总览")])]},proxy:true},{key:"query",fn:function(ref){
var form = ref.form;
return [_c('a-form-model-item',{attrs:{"label":"所在区域"}},[_c('a-select',{staticStyle:{"width":"180px"},attrs:{"placeholder":"选择区域筛选","allow-clear":"","options":_vm.areaList},on:{"change":_vm.updateList},model:{value:(form.district_id),callback:function ($$v) {_vm.$set(form, "district_id", $$v)},expression:"form.district_id"}})],1),_c('a-form-model-item',{attrs:{"label":"龛位编号"}},[_c('a-input-search',{staticStyle:{"width":"180px"},attrs:{"allow-clear":"","placeholder":"输入龛位编号搜索"},on:{"search":_vm.updateList},model:{value:(form.num),callback:function ($$v) {_vm.$set(form, "num", $$v)},expression:"form.num"}})],1)]}},{key:"edit",fn:function(ref){
var form = ref.form;
return [_c('a-form-model-item',{attrs:{"label":"所在区域"}},[_vm._v(_vm._s(form.district && form.district.name))]),_c('a-form-model-item',{attrs:{"label":"龛位编号","prop":"name"}},[_c('a-input',{attrs:{"placeholder":"输入龛位编号（最多5个字符）","maxLength":5,"allow-clear":""},model:{value:(form.num),callback:function ($$v) {_vm.$set(form, "num", $$v)},expression:"form.num"}})],1),_c('a-form-model-item',{attrs:{"label":"龛位价格","prop":"price"}},[_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"min":0.01,"precision":2,"placeholder":"请输入龛位价格"},model:{value:(form.priceNum),callback:function ($$v) {_vm.$set(form, "priceNum", $$v)},expression:"form.priceNum"}})],1),_c('a-form-model-item',{attrs:{"label":"是否对外开放","prop":"status"}},[_c('a-switch',{model:{value:(form.status),callback:function ($$v) {_vm.$set(form, "status", $$v)},expression:"form.status"}})],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('a',{staticClass:"txt-btn",on:{"click":function($event){$event.stopPropagation();return _vm.goDetail(item, index)}}},[_vm._v("查看订单")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }